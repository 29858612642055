import { Button } from 'primereact/button';
import login from '../assets/login.png';
import AppLayout from '../components/layout/AppLayout';

import { useNavigate } from 'react-router';
const MenuPage = () => {
	const navigate = useNavigate();
	return (
		<AppLayout>
			<div className='align-items-center flex justify-content-center lg:px-8 md:px-6  ng-star-inserted h-full'>
				<div className='p-4 shadow-4 border-round w-full lg:w-6 bg-gray-200'>
					<div className='text-center mb-5'>
						<img src={login} alt='Image' height='100' className='mb-3 br-5' />
						<div className='text-900 text-3xl font-medium mb-3'>Bienvenid@</div>
						<div className='col-12'>
							<Button
								label='Entrada'
								style={{ width: '100%' }}
								onClick={() => {
									navigate('/nuevoMovimiento?entrada=true');
								}}
							/>
						</div>
						<div className='col-12'>
							<Button
								label='Salida'
								style={{ width: '100%' }}
								severity='danger'
								onClick={() => {
									navigate('/nuevoMovimiento?entrada=false');
								}}
							/>
						</div>
						<div className='col-12'>
							<Button
								label='Productos'
								style={{ width: '100%' }}
								severity='info'
								onClick={() => {
									navigate('/productos');
								}}
							/>
						</div>
						<div className='col-12'>
							<Button
								label='Nuevo producto'
								style={{ width: '100%' }}
								severity='help'
								onClick={() => {
									navigate('/nuevoProducto');
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default MenuPage;
