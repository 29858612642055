import { InputText } from 'primereact/inputtext';
import AppLayout from '../components/layout/AppLayout';
import useProductosFetch from '../hooks/useProductosFetch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router';
import { InputTextarea } from 'primereact/inputtextarea';
         
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { cloneDeep, uniqBy } from 'lodash';
import ImagenesProducto from '../components/ImagenesProducto';

const ProductoFormPage = () => {
	const { productos, familias, saveProducto } = useProductosFetch();
	const { id } = useParams();
	const [producto, setProducto] = useState();

	const productoWS = useMemo(() => productos?.find((p) => p.id === parseInt(id, 10)), [id, productos]);

	const navigate = useNavigate();

	useEffect(() => {
		setProducto(cloneDeep(productoWS));
	}, [productoWS]);

	const onChangeElement = useCallback(
		(name, value) => {
			const u = { ...producto };
			u[name] = value;
			setProducto(u);
		},
		[producto]
	);

	const familiasD = useMemo(
		() =>
			familias?.map((f) => ({
				label: f.nombre,
				value: f.id,
			})),
		[familias]
	);
	const marcasD = useMemo(
		() =>
			uniqBy(productos?.map((f) => ({
				label: f.marca,
				value: f.marca,
			})),'value'),
		[productos]
	);

	const onSaveProducto = useCallback(() => {
		saveProducto(producto);
	}, [producto, saveProducto]);

	if (window.productoGuardado) {
		window.productoGuardado = false;
		navigate('/productos');
	}

	return (
		<AppLayout>
			{!productoWS && <h2 className='p-0 m-0'>Nuevo producto</h2>}
			{productoWS && <h2 className='p-0 m-0'>{productoWS.nombre} </h2>}
			<div className='lg:px-8 md:px-6 px-1 py-1 surface-section ng-star-inserted'>
				<div className='grid m-0 p-0 flex align-items-center bg-gray-100 m-1  '>
					<ul className='col-12 list-none p-0 m-0'>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Familia</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<Dropdown
									onChange={(e) => onChangeElement('familia', e.value)}
									options={familiasD}
									value={producto?.familia}
									placeholder='Seleccione familia'
									className='w-full'
									showClear
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Marca</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<Dropdown
									onChange={(e) => onChangeElement('marca', e.value)}
									options={marcasD}
									value={producto?.marca}
									placeholder='Seleccione marca'
									className='w-full'
									showClear
								/>
							</div>
						</li>

						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Código</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputText
									className='w-full'
									rows={10}
									value={producto?.codigoP}
									onChange={(e) => onChangeElement('codigoP', e.target.value)}
								/>
							</div>
						</li>

						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Nombre</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputText
									className='w-full'
									value={producto?.nombre}
									onChange={(e) => onChangeElement('nombre', e.target.value)}
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Descripcion</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputTextarea
									className='w-full'
									value={producto?.descripcion}
									onChange={(e) => onChangeElement('descripcion', e.target.value)}
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Pvp</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputNumber
									value={producto?.pvp}
									onChange={(e) => onChangeElement('pvp', e.value)}
									mode='decimal'
									maxFractionDigits={2}
									locale='es-ES'
								/>
							</div>
						</li>
						<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
							<div className='text-500 w-6 md:w-2 font-medium'>Pvp Oferta</div>
							<div className='text-900 w-full md:w-8 md:flex-order-0 flex-order-1'>
								<InputNumber
									value={producto?.pvpOferta}
									onChange={(e) => onChangeElement('pvpOferta', e.value)}
									mode='decimal'
									locale='es-ES'
									maxFractionDigits={2}
								/>
							</div>
						</li>
					</ul>
				</div>

				<div className='col-12 font-medium text-3xl text-900 mb-3'>
					<Button label='Guardar cambios' severity='danger' onClick={onSaveProducto} />
				</div>
				{producto?.id && <ImagenesProducto id={producto.id} />}
			</div>
		</AppLayout>
	);
};

export default ProductoFormPage;
