import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getImagenesLiteService, saveImagenService } from "../services/almacenService";
import { toast } from "react-toastify";


const useImagenesFetch = () => {
	const queryClient = useQueryClient();
	const { data: imagenes } = useQuery({
		queryKey: ['imagenesLite'],
		queryFn: async () => await getImagenesLiteService(),
	});

    const { mutate: saveImagen } = useMutation({
			mutationFn: saveImagenService,
			onSuccess: () => {
				toast('Imagen guardada correctamente');
				queryClient.invalidateQueries({ queryKey: ['imagenesLite'] });
				window.movimientoGuardado = true;
			},
			onError: () => {
				toast('Error guardando imagen');
			},
		}); 

	return { imagenes, saveImagen };
};
export default useImagenesFetch;