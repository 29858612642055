
import { useAuthUser } from './hooks/useAuthUser';
import LoginPage from './pages/LoginPage';
import { Route, Routes } from 'react-router';
import MenuPage from './pages/MenuPage';
import RequireAuth from './auth/RequireAuth';
import ProductosPage from './pages/ProductosPage';
import ProductoFormPage from './pages/ProductoFormPage';
import AlmacenPage from './pages/AlmacenPage';
import MovimientosPage from './pages/MovimientosPage';
import MovimientoFormPage from './pages/MovimientoFormPage';
import ZascaTactical from './pages/ZascaTactical';
import ConsultaEanPage from './pages/ConsultaEanPage';

const Main = ()=> { 
	useAuthUser();

     return (
				<Routes>
					<Route exact path='/login' element={<LoginPage />} />
					<Route
						exact
						path='/'
						element={
							<RequireAuth>
								<MenuPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/productos'
						element={
							<RequireAuth>
								<ProductosPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/nuevoProducto'
						element={
							<RequireAuth>
								<ProductoFormPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/producto/:id'
						element={
							<RequireAuth>
								<ProductoFormPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/almacen/:id'
						element={
							<RequireAuth>
								<AlmacenPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/movimientos/:id'
						element={
							<RequireAuth>
								<MovimientosPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/nuevoMovimiento'
						element={
							<RequireAuth>
								<MovimientoFormPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/zascaTactical'
						element={
							<RequireAuth>
								<ZascaTactical />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/buscaEan'
						element={
							<RequireAuth>
								<ConsultaEanPage />
							</RequireAuth>
						}
					/>
				</Routes>
			);
}

export default Main;