import {  useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import useProductosFetch from '../hooks/useProductosFetch';
import useImagenesFetch from '../hooks/useImagenesFetch';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const ImagenesProducto = ({id}) => {
    const [imagen, setImagen]= useState();
	const {productos}=useProductosFetch();
    const {imagenes,saveImagen} = useImagenesFetch()
    const producto = useMemo(()=> productos?.find(p=>p.id===parseInt(id,10)),[productos, id])
   
    const saveImagenNueva = useCallback(()=>{
        saveImagen({producto:id, url: imagen});
        setImagen('');
    },[id, imagen, saveImagen])
  
	return (
		<>
			<h2 className='p-0 m-0'>Imágenes {producto?.nombre}</h2>

			<div className='grid align-items-center justify-content-center  m-0 p-0'>
				<div className='col-11'>
					<InputText onChange={(e) => setImagen(e.target.value)} value={imagen} style={{ width: '600px' }} />
					<Button onClick={saveImagenNueva} icon='pi pi-save' />
				</div>

				{imagenes?.filter(i=>i.producto===id).map((i) => (
					<div className='col-2 ' key={i.id}>
						<Image src={i.url} alt='Image' width='250' preview />
					</div>
				))}
			</div>
		</>
	);
};
 
ImagenesProducto.propTypes={
	id: PropTypes.number
}
export default ImagenesProducto;
