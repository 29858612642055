import { useCallback, useState } from 'react';
import AppLayout from '../components/layout/AppLayout';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import useFetchAlmacen from '../hooks/useFetchAlmacen';
import { useParams } from 'react-router';
import { orderBy } from 'lodash';
import useImagenesFetch from '../hooks/useImagenesFetch';
import { Image } from 'primereact/image';
import moment from 'moment';

const AlmacenPage = () => {
	const { id } = useParams();
		const { imagenes } = useImagenesFetch({});
	const { estadoAlmacen, isLoadingEstadoAlmacen } = useFetchAlmacen({ id });
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};
	const renderHeader = () => {
		return (
			<div className='flex justify-content-end'>
				<span className='p-input-icon-left'>
					<i className='pi pi-search' />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Buscar nombre/zona..' />
				</span>
			</div>
		);
	};

	const renderFecha = useCallback((row) => moment(new Date(row.ultimoMovimiento)).format('DD-MM-yyy'), []);

	const renderImage = useCallback(
		(row) => {
			const imagesP = imagenes?.filter((i) => i.producto === row.producto.id);
			if (imagesP?.length) {
				return <Image src={imagesP[0].url} alt='Image' height='60' preview key={imagesP[0].id} />;
				
			}
			return null;
		},
		[imagenes]
	);

	return (
		<AppLayout>
			<h2 className='p-0 m-0'>Estado Almacen {id === '1' ? 'Zascalo' : 'Outlet'}</h2>

			<div className='grid align-items-center justify-content-center  m-0 p-0'>
				<div className='col-11 p-3'>
					<DataTable
						loading={isLoadingEstadoAlmacen}
						value={orderBy(estadoAlmacen, ['unidades'], ['desc'])}
						stripedRows
						size='small'
						filters={filters}
						globalFilterFields={['producto.codigoP', 'producto.nombre']}
						header={renderHeader}
					>
						<Column header='' body={renderImage} />
						<Column header='Ean' field='producto.codigoP' style={{ width: '90px' }} />
						<Column header='Nombre' field='producto.nombre' sortable></Column>
						<Column header='Unidades' field='unidades' style={{ width: '90px' }} sortable></Column>
						<Column header='Precio almacenado' field='precioAlmacenado' style={{ width: '90px' }} sortable></Column>
						<Column
							header='Ultimo Mov.'
							field='ultimoMovimiento'
							style={{ width: '120px' }}
							sortable
							body={renderFecha}
						></Column>
					</DataTable>
				</div>
			</div>
		</AppLayout>
	);
};

export default AlmacenPage;
